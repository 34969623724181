<template>
	<li
		class="phone-select"
		@mouseover='openPhoneSelect()'
		@mouseout='closePhoneSelect()'
	>
		<button class="phone-select__trigger">
			<span>
				{{ $t('order.phone') }}
			</span>
			<i v-if='phoneSelectOpen' class="fas fa-chevron-up is-visible"></i>
			<i v-else class="fas fa-chevron-down is-visible"></i>
		</button>
		<ul
			class="phone-select__menu"
			:class="{'is-open': phoneSelectOpen}"
			@click='closePhoneSelect()'
			@mouseover='onPhoneSelectHover()'
			@mouseout='offPhoneSelectHover()'
		>
			<li v-if="settings && settings.tel">
				<a :href="'tel:' + settings.tel" class="button is-red is-small">
					<span>{{ $t('order.phone_zoli', {phone: settings.tel}) }}</span>
				</a>
			</li>
			<li v-if="settings2 && settings2.tel">
				<a :href="'tel:' + settings2.tel" class="button is-red is-small">
					<span>{{ $t('order.phone_wola', {phone: settings2.tel}) }}</span>
				</a>
			</li>
		</ul>
	</li>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	data: () => ({
		phoneSelectOpen: document.documentElement.clientWidth < 1100,
		phoneSelectHover: false,
	}),
	computed: {
		...mapGetters({
			settings: 'settings',
			settings2: 'settings2',
		})
	},
	methods: {
		openPhoneSelect () {
			setTimeout(() => {
				if (document.documentElement.clientWidth >= 1100) {
					this.phoneSelectOpen = true
				}
			}, 100)
		},
		closePhoneSelect () {
			setTimeout(() => {
				if (document.documentElement.clientWidth >= 1100 && !this.phoneSelectHover) {
					this.phoneSelectOpen = false
				}
			}, 100)
		},
		onPhoneSelectHover () {
			this.phoneSelectHover = true
		},
		offPhoneSelectHover () {
			this.phoneSelectHover = false
			this.closePhoneSelect()
		},
	}
}
</script>

<style lang="scss">
@import '../../assets/css/breakpoints.scss';

.phone-select {
	position: relative;

	&__trigger {
		@include bp-mobilenav {
			display: none !important;
		}

		color: var(--white);
		background-color: transparent;
		border-color: transparent;
		font-weight: bold;
		font-size: 16px;
		letter-spacing: 0.4px;
		text-transform: uppercase;

		i {
			color: var(--white);
			margin-left: 5px;
		}
	}

	&__menu {
		display: none;

		flex-direction: column;
		position: absolute;
		bottom: 0;
		right: 0;
		align-items: flex-end;
		justify-content: center;
		transform: translate(0, 99%);

		@include bp-mobilenav {
			display: flex !important;
			position: relative;
			align-items: center;
			transform: translate(0, 0);

			> li > a {
				margin-top: 10px;
			}
		}

		&.is-open {
			display: flex;
		}

		> li {
			margin: 2.5px;

			> a {
				white-space: nowrap;
			}
		}

	}
}
</style>